@import "../../stylesheets/base/module";

.table {
  height: calc(100vh - #{$header-height});
}

.mergeConflictView {
  width: 100%;
}

.mergeConflictViewLeftPane {
  width: 500px;
  float: left;
}

.searchMenu {
  position: absolute;
  right: 0;
  top: 0;
  bottom: $header-height;

  & > div {
    float: left
  }

  .searchBar {
    line-height: $header-height;
    margin-right: 10px;
  }

}

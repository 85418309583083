
@mixin type-sizes($values) {

    @each $elem, $font-size, $line-height, $weight in $values {
        %#{$elem}-typo, #{$elem} {
            font-size: $font-size * 1px;
            line-height: $line-height * 1px;
            font-weight: $weight;
            font-weight: 300;
        }
    }
}

@include type-sizes(
      (h1   14 53 bold)
      (h2   14 43 bold)
      (h3   14 38 bold)
      (h4   14 33 bold)
      (h5   14 28 bold)
      (h6   14 23 bold)
      (h7   14 18 bold)
      (body 14 27 300)
      (input 14 18 300)
      (th 12 18 bold)
      (td 14 30 300)
      (button 12 15 normal)
  );

body, input, button, textarea, option, select {
    font-family: Futura,Trebuchet MS,Arial,sans-serif;
}
h1 {
    text-transform: uppercase;
}

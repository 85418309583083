@import '../../stylesheets/base/_module.scss';

.leftpanel {
    position: absolute;
    top: 35px;
    left: 0;
    bottom: 0;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    width: calc(100% - 490px);
    background-image: url('../../../public/assets/login-bg-large.jpg');
}

.rightpanel {
    position: absolute;
    top: 35px;
    right: 0;
    bottom: 0;
    width: 530px;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.vmdbheader {
    height: 170px;
    min-height: 170px;
    background-color: rgb(229, 229, 229);
    display: flex;
    align-items: center;

    span {
        font-size: 30px;
        margin-left: 20px;
    }
}

.textblock {
    padding: 100px 50px 0 50px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.signup {
    margin-bottom: 20px;
    display: inline-block;
}

.signin {
    font-size: 28px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.signup {
    font-size: 17px;
}

.welcome {
    font-size: 15px;
    line-height: 21px;
    margin-block-start: 6px;
    margin-block-end: 6px;
}

.btn {
    margin-top: 50px;
    max-width: 400px;
    width: 380px;
    height: 40px;
    line-height: inherit;
}

.wrapper {
    padding: 20px;
    background-color: white;
    width: 90vw;
    max-width: 900px;
    margin: 20px auto;
    box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.1);
}

@import 'base/module';
html, body {
    background-color: $background-grey;
    color: $dark-blue;

    // disable "overscrolling" on mac
    width: 100%;
    height: 100%;
    overflow: hidden;
}

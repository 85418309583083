@import '../../stylesheets/base/module';

$header-height: $dt-size-m;
$editor-height: 50%;
$tab-buttons-height: $header-height;
$search-width: 400px;
$header-width: 300px;
.app {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    .header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: $header-height;
    }

    .search {
        position: absolute;
        left: 0;
        top: $header-height;
        bottom: 0;
        width: $search-width;
        border-right: 2px solid $black;
    }

    .history, .editor, .loader {
        position: absolute;
        right: 0;
        left: 0;
        top: $header-height;
        bottom: 0;
    }
    .rightPane {
        position: absolute;
        top: 0;
        left: $search-width;
        right: 0;
        bottom:0;
    }

    .tabButtons {
        position: absolute;
        top: 0;
        height: $tab-buttons-height;
        left: $header-width;
        line-height: $dt-size-m;
    }
    .tabButton {
        padding: 0;
        text-transform: uppercase;
        font-weight: bold;
        border: 0;
        color: white;
        background: transparent;
        margin: 0 $dt-size-s;
        border-bottom: 2px solid transparent;
        border-top: 2px solid transparent;
        cursor: pointer;
        &.active {
            border-bottom-color: white;
        }
        &:focus, &:active {
            outline: none;
        }
    }
    .noVessel {
        position: absolute;
        right: 0;
        left: 0;
        top: $header-height;
        bottom: 0;
        padding: $dt-size-xxs $dt-size-s;
    }
}
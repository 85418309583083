@import '../../stylesheets/base/module';

.historyJson {
  font-size: 10pt;
  line-height: 20px;
}

.searchMenu {
  position: absolute;
  right: 0;
  top: 0;
  bottom: $header-height;

  & > div {
    float: left
  }

  .searchBar {
    line-height: $header-height;
    margin-right: 10px;
  }

}
// desktop sizes
$dt-size-xxs:  10px;
$dt-size-xs:   20px;
$dt-size-s:    30px;
$dt-size-m:    40px;
$dt-size-l:    60px;
$dt-size-xl:   80px;
$dt-size-xxl: 100px;

// tablet sizes
$tb-size-xxs:  5px;
$tb-size-xs:  10px;
$tb-size-s:   20px;
$tb-size-m:   30px;
$tb-size-l:   40px;
$tb-size-xl:  60px;
$tb-size-xxl: 80px;

// mobile sizes
$mb-size-xxs:  5px;
$mb-size-xs:  10px;
$mb-size-s:   15px;
$mb-size-m:   20px;
$mb-size-l:   30px;
$mb-size-xl:  40px;
$mb-size-xxl: 60px;

@import '../../stylesheets/base/_module.scss';


.header {
  background: $black;
  color: $white;
  height: $dt-size-m;
  padding: 0 $dt-size-s;
  align-items: center;

  h1 {
    line-height: $dt-size-m;
    font-weight: bold;
    display: inline-block;
  }

  .por-logo {
    $size: 18px;
    width: $size;
    height: $size;
    display: inline-block;
    vertical-align: middle;

    path {
      fill: $white;
    }
  }
}

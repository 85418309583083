@import "../../stylesheets/base/module";

.searchMenu {
  position: absolute;
  right: 0;
  top: 0;
  bottom: $header-height;

  & > div {
    float: left
  }

  .searchBar {
    line-height: $header-height;
    margin-right: 10px;
  }

}

.viewRawData {
  padding: 0;
  text-transform: uppercase;
  font-weight: bold;
  border: 0;
  background: transparent;
  margin: 0 $dt-size-s;
  cursor: pointer;
  float: right;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;

  &.active {
    border-bottom-color: black;
  }
  &:hover, &:active {
    outline: none;
    border-bottom-color: black;
  }
}
.searchLabel {
  color: white;
  margin-left: 1em;
  margin-right: 1em;
}
.table {
  height: calc(100vh - #{$header-height});
}

.mergeConflictColor {
   background: #ff3b3b;
}


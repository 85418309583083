// Primary colors
$black: #222222;
$yellow: #FFD800;
$cyan: #0693CA;
$dark-blue: #001F4B;

$white: #fff;

// greys
$grey-100: #958D83;
$grey-75: #C0BBB5;
$grey-50: #D5D1CD;
$grey-25: #EAE8E6;
$grey-10: #F6F5F5;
$natural-gray: #958d83;

// sky blues
$sky-blue-100: #C2DEEA;
$sky-blue-75: #D2E7F0;
$sky-blue-50: #E0EEF4;
$sky-blue-25: #F0F7FA;
$sky-blue-10: #F8FBFC;
// named

$background-grey: $grey-10;
$background-white: $white;
$color-disabled: $grey-50;

// label colors
$label-info: #D3CFCB;
$label-info-inverse: #F0722E;

.close {
  padding: 10px;
}

.icon {
  font-size: 20pt
}

.icon-variant {
  opacity: 0.9;
  margin-right: 10px;
}
